import { Link, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DocumentIcon, ExclamationTriangleIcon, LockLockedIcon, PenIcon } from '@trawa-energy/ui-kit';
import { Temporal } from 'temporal-polyfill';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { LoadFileComponent } from '../components/LoadProfileUpload.tsx';
import { TextInputField } from '../components/TextInputField.tsx';
import { CustomConfigContext } from '../context/CustomConfigContext.tsx';
import classNames from 'classnames';
import GoogleAutocomplete from '../components/GoogleAutocomplete.tsx';
import { ToastWrapper } from '../components/Toast.tsx';
import { showErrorToast, showSuccessToast } from '../utils/showToasts.ts';
import { Mixpanel } from '../../utils/setupMixpanel.ts';

export type FormValues = {
    companyName: string;
    ownGeneration: {
        hasOwnPV: boolean;
        additionalSubtractPV: boolean;
        isKWhUnit: string;
        amountPV: number;
    };
    sector: {
        isIndustry: string;
        amountOfCounters: number;
    };
    fees: {
        hasFees: boolean;
        address: {
            google: string;
            street: string;
            houseNumber: string;
            postCode: string;
            location: string;
        };
        isSlp: string;
        supplyVoltage: keyof typeof supplyVoltageMap;
        meterVoltage: keyof typeof meterVoltageMap;
    };
};

const supplyVoltageMap = {
    NSP: 'Niederspannung (NSP)',
    MSP: 'Mittelspannung (MSP)',
    HSP: 'Hochspannung (HSP)',
    MSP_NSP_UMSP: 'Umspannung MSP auf NSP',
    HSP_MSP_UMSP: 'Umspannung HSP auf MSP',
};

const meterVoltageMap = {
    NSP: 'Niederspannung (NSP)',
    MSP: 'Mittelspannung (MSP)',
    HSP: 'Hochspannung (HSP)',
};

export function ConsumptionAnalysis() {
    const { dealId } = useParams();
    const intl = useIntl();
    const [errorUpdating, setErrorUpdating] = useState<string | undefined>(undefined);
    const [submitPending, setSubmitPending] = useState(false);
    const [isEdit, setIsEdit] = useState(true);
    const customConfigContext = useContext(CustomConfigContext);
    const [gridFeesFullAddress, setGridFeesFullAddress] = useState<string | undefined>();
    const [isResettingGridFees, setIsResettingGridFees] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        getValues,
        setValue,
        setError,
        clearErrors,
        control,
    } = useForm<FormValues>({
        defaultValues: {
            companyName: '',
            ownGeneration: {
                hasOwnPV: false,
                additionalSubtractPV: false,
                isKWhUnit: 'true',
                amountPV: 0,
            },
            sector: {
                isIndustry: 'true',
                amountOfCounters: 1,
            },
            fees: {
                hasFees: false,
                address: {
                    google: '',
                    street: '',
                    houseNumber: '',
                    postCode: '',
                    location: '',
                },
                isSlp: 'true',
                supplyVoltage: 'NSP',
                meterVoltage: 'NSP',
            },
        },
        mode: 'onChange',
    });

    if (!dealId) {
        throw new Error("Can't render DealView without dealId");
    }

    const { data: deal, refetch: refetchDeal } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });

    const {
        mutateAsync: updateCustomerConfig,
        isError,
        isPending,
    } = trpc.deals.updateCustomerConfig.useMutation({ onSuccess: () => customConfigContext?.refetch() });

    const { mutateAsync: updateContractData } = trpc.deals.updateDeal.useMutation({ onSuccess: () => refetchDeal() });

    const watchHasOwnPV = watch('ownGeneration.hasOwnPV');
    const watchIsKWhUnit = watch('ownGeneration.isKWhUnit') === 'true';
    const watchAmountPV = watch('ownGeneration.amountPV');
    const watchHasFees = watch('fees.hasFees');

    useEffect(() => {
        if (deal?.hasLoadFile) {
            setIsEdit(false);
        }
        if (customConfigContext?.config?.contract_settings.sector) {
            setValue(
                'sector.isIndustry',
                customConfigContext?.config?.contract_settings.sector === 'industry' ? 'true' : 'false',
            );
        }
        if (customConfigContext?.config?.contract_settings.number_of_market_locations) {
            setValue(
                'sector.amountOfCounters',
                customConfigContext.config.contract_settings.number_of_market_locations,
            );
        }
        if (customConfigContext?.config?.core_opt_settings.onsite_solar) {
            setValue('ownGeneration.amountPV', customConfigContext.config.core_opt_settings.onsite_solar);
            setValue('ownGeneration.hasOwnPV', true);
        }

        setValue(
            'ownGeneration.additionalSubtractPV',
            !!customConfigContext?.config?.core_opt_settings.onsite_reduction_activate,
        );

        // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        setValue('companyName', deal?.name || '');

        setValue('fees.hasFees', !!customConfigContext?.config?.grid_fees_address?.street);

        setValue('fees.address', {
            google: customConfigContext?.config?.grid_fees_address?.google ?? '',
            street: customConfigContext?.config?.grid_fees_address?.street ?? '',
            houseNumber: customConfigContext?.config?.grid_fees_address?.house_number ?? '',
            postCode: customConfigContext?.config?.grid_fees_address?.post_code ?? '',
            location: customConfigContext?.config?.grid_fees_address?.location ?? '',
        });

        if (customConfigContext?.config?.grid_fees_address?.google) {
            setGridFeesFullAddress(
                `${customConfigContext?.config?.grid_fees_address?.street} ${customConfigContext?.config?.grid_fees_address?.house_number}, ${customConfigContext?.config?.grid_fees_address?.post_code}, ${customConfigContext?.config?.grid_fees_address?.location}`,
            );
        }
    }, [customConfigContext?.config, setValue, deal]);

    const getValueAndUnit = useMemo(() => {
        if (watchAmountPV) {
            const value = watchIsKWhUnit ? watchAmountPV / 950 : watchAmountPV * 950;
            return intl.formatMessage(
                { id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.convertLabel' },
                {
                    value: intl.formatNumber(value, { maximumFractionDigits: 3 }),
                    unit: watchIsKWhUnit ? 'kWp' : 'kWh',
                },
            );
        }
        return '';
    }, [watchIsKWhUnit, watchAmountPV, intl]);

    const onSubmit = async (data: FormValues) => {
        setSubmitPending(true);
        if (!customConfigContext?.config) {
            setErrorUpdating(intl.formatMessage({ id: 'deals.consumptionAnalysis.ownElectricity.error.noConfig' }));
            return;
        }

        setErrorUpdating(undefined);

        try {
            if (deal?.name !== data.companyName) {
                await updateContractData({ dealId, name: data.companyName });
            }
        } catch (e) {
            let errorMessage;
            if (e instanceof Error) {
                errorMessage = e.message.split(':')[1];
            }
            showErrorToast(
                <p className="whitespace-break-spaces">
                    {intl.formatMessage(
                        { id: 'deals.consumptionAnalysis.toast.error' },
                        {
                            cause: errorMessage,
                        },
                    )}
                </p>,
            );
        }

        try {
            await updateCustomerConfig({
                dealId,
                updatedConfig: {
                    ...customConfigContext.config,
                    contract_settings: {
                        ...customConfigContext.config.contract_settings,
                        sector: data.sector.isIndustry === 'true' ? 'industry' : 'real-estate',
                        number_of_market_locations: Number(data.sector.amountOfCounters),
                    },
                    core_opt_settings: {
                        maximum_fixed: customConfigContext.config.core_opt_settings.maximum_fixed,
                        onsite_reduction_activate: data.ownGeneration.additionalSubtractPV,
                        onsite_solar: data.ownGeneration.hasOwnPV
                            ? data.ownGeneration.isKWhUnit === 'true'
                                ? Number(data.ownGeneration.amountPV)
                                : Number(data.ownGeneration.amountPV) * 950
                            : 0,
                    },
                    prices: {
                        ...customConfigContext.config.prices,
                        price_levies: 0,
                        price_electricity_tax: 0,
                        price_grid_fees: 0,
                        price_licence_fees: 0,
                    },
                    grid_fees_address: {
                        ...customConfigContext.config.grid_fees_address,
                        street: data.fees.hasFees ? data.fees.address.street : '',
                        house_number: data.fees.hasFees ? data.fees.address.houseNumber : '',
                        post_code: data.fees.hasFees ? data.fees.address.postCode : '',
                        location: data.fees.hasFees ? data.fees.address.location : '',
                        google: data.fees.hasFees ? data.fees.address.google : '',
                    },
                },
                gridFeesInput: data.fees.hasFees
                    ? {
                          supplyVoltage: data.fees.supplyVoltage,
                          meteringVoltage: data.fees.meterVoltage,
                          marketLocationType: data.fees.isSlp === 'true' ? 'SLP' : 'RLM',
                          numberOfMeters: Number(data.sector.amountOfCounters),
                          street: data.fees.address.street,
                          postCode: data.fees.address.postCode,
                          houseNumber: data.fees.address.houseNumber,
                          location: data.fees.address.location,
                          startDate: Temporal.Now.plainDateISO(intl.timeZone)
                              .add({ years: 1 })
                              .with({ month: 1, day: 1 })
                              .toString(),
                      }
                    : undefined,
            });

            Mixpanel.track('Prospects consumption updated', {
                Industry: data.sector,
                'has PV production': data.ownGeneration.hasOwnPV,
                'Gridfees calculated': data.fees.hasFees,
            });
            showSuccessToast(intl.formatMessage({ id: 'deals.consumptionAnalysis.toast.success' }));
            setIsEdit(false);
        } catch (e) {
            let errorMessage;
            if (e instanceof Error) {
                errorMessage = e.message.split(':')[1];
            }
            Mixpanel.track('Prospects consumption error', {
                error: errorMessage,
            });
            showErrorToast(
                <p className="whitespace-break-spaces">
                    {intl.formatMessage(
                        { id: 'deals.consumptionAnalysis.toast.error' },
                        {
                            cause: errorMessage,
                        },
                    )}
                </p>,
            );
        } finally {
            setSubmitPending(false);
            setIsResettingGridFees(false);
        }
    };

    const resetGridFees = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setValue('fees.hasFees', false);
        setValue('fees.address', { google: '', street: '', houseNumber: '', postCode: '', location: '' });
        setIsResettingGridFees(true);
    };

    return (
        <div>
            <div className="my-8">
                <section>
                    <h1 className="text-2xl">
                        {intl.formatMessage({ id: 'deals.consumptionAnalysis.subHeading' }, { dealId: dealId })}
                    </h1>
                    <TextInputField
                        label={intl.formatMessage({
                            id: 'deals.consumptionAnalysis.companyName',
                        })}
                        placeholder={intl.formatMessage({
                            id: 'deals.consumptionAnalysis.companyNamePlaceholder',
                        })}
                        isDisabled={submitPending || !isEdit}
                        hasError={!!errors.companyName}
                        {...register('companyName', { required: true })}
                    />
                </section>
                <section className="my-8">
                    <h2>{intl.formatMessage({ id: 'deals.consumptionAnalysis.loadProfile.title' })}</h2>
                    {deal?.hasLoadFile && (
                        <div className="flex my-8 gap-8">
                            <div>
                                {intl.formatMessage({ id: 'deals.consumptionAnalysis.loadProfile.currentFile.label' })}
                            </div>
                            <div className="flex gap-2">
                                <DocumentIcon /> {deal?.loadFileName ?? 'load.xlsx'}
                            </div>
                            <div>
                                {intl.formatMessage(
                                    { id: 'deals.consumptionAnalysis.loadProfile.currentFile.createdAt' },
                                    {
                                        date: intl.formatDate(deal.createdAtLoadFile),
                                        time: intl.formatTime(deal.createdAtLoadFile),
                                    },
                                )}
                            </div>
                        </div>
                    )}
                    {deal && (
                        <LoadFileComponent
                            hasLoadFile={!!deal?.hasLoadFile}
                            dealId={deal.id}
                            disabled={!isEdit}
                            refetchDeal={refetchDeal}
                        />
                    )}
                </section>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <fieldset className="my-8">
                        <h2>{intl.formatMessage({ id: 'deals.consumptionAnalysis.sector.title' })}</h2>
                        <div className="join my-4">
                            <input
                                {...register('sector.isIndustry')}
                                type="radio"
                                value="true"
                                className="btn btn-sm join-item"
                                aria-label={intl.formatMessage({
                                    id: 'deals.consumptionAnalysis.sector.sectorToggle.sector1',
                                })}
                                disabled={submitPending || !isEdit}
                            />
                            <input
                                {...register('sector.isIndustry')}
                                type="radio"
                                value="false"
                                className="btn btn-sm join-item"
                                aria-label={intl.formatMessage({
                                    id: 'deals.consumptionAnalysis.sector.sectorToggle.sector2',
                                })}
                                disabled={submitPending || !isEdit}
                            />
                        </div>

                        <TextInputField
                            label={intl.formatMessage({
                                id: 'deals.consumptionAnalysis.sector.electricityCounters.label',
                            })}
                            placeholder={intl.formatMessage({
                                id: 'deals.consumptionAnalysis.sector.electricityCounters.placeholder',
                            })}
                            isDisabled={submitPending || !isEdit}
                            hasError={!!errors.sector?.amountOfCounters}
                            bottomLeftErrorLabel={errors.sector?.amountOfCounters?.message}
                            {...register('sector.amountOfCounters', {
                                required: true,
                                pattern: {
                                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                    message: intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.sector.electricityCounters.error.pattern',
                                    }),
                                },
                                min: {
                                    value: 1,
                                    message: intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.sector.electricityCounters.error.min',
                                    }),
                                },
                                max: {
                                    value: 10000,
                                    message: intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.sector.electricityCounters.error.max',
                                    }),
                                },
                            })}
                        />
                    </fieldset>
                    <fieldset className="my-8">
                        <h2 className="mb-4">
                            {intl.formatMessage({ id: 'deals.consumptionAnalysis.ownElectricity.title' })}
                        </h2>
                        {isError && (
                            <div role="alert" className="alert alert-error text-warning-content mb-4">
                                <ExclamationTriangleIcon />
                                <span>
                                    {intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.ownElectricity.error.saveChanges',
                                    })}
                                </span>
                            </div>
                        )}
                        {errorUpdating && (
                            <div role="alert" className="alert alert-error mb-4">
                                <ExclamationTriangleIcon />
                                <span>
                                    {intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.ownElectricity.error.noConfig',
                                    })}
                                </span>
                            </div>
                        )}
                        <div className="flex flex-col gap-8">
                            <div className="flex gap-4">
                                <label className="label cursor-pointer">
                                    <input
                                        {...register('ownGeneration.hasOwnPV')}
                                        type="checkbox"
                                        className="checkbox mr-2"
                                        disabled={submitPending || !isEdit}
                                    />
                                    <span className="label-text text-base">
                                        {intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.ownElectricity.pvCheckbox.label',
                                        })}
                                    </span>
                                </label>
                                <label className="label cursor-pointer">
                                    <input
                                        {...register('ownGeneration.additionalSubtractPV')}
                                        type="checkbox"
                                        className="checkbox mr-2"
                                        disabled={!watchHasOwnPV || submitPending || !isEdit}
                                    />
                                    <span className="label-text text-base">
                                        {intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.ownElectricity.subtractPVCheckbox.label',
                                        })}
                                    </span>
                                </label>
                            </div>
                            <div className="join">
                                <input
                                    {...register('ownGeneration.isKWhUnit')}
                                    type="radio"
                                    value="true"
                                    className="btn btn-sm join-item"
                                    aria-label={intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.ownElectricity.unitPVToggle.labelKWH',
                                    })}
                                    disabled={!watchHasOwnPV || submitPending || !isEdit}
                                />
                                <input
                                    {...register('ownGeneration.isKWhUnit')}
                                    type="radio"
                                    value="false"
                                    className="btn btn-sm join-item"
                                    aria-label={intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.ownElectricity.unitPVToggle.labelKWP',
                                    })}
                                    disabled={!watchHasOwnPV || submitPending || !isEdit}
                                />
                            </div>
                            <TextInputField
                                {...register('ownGeneration.amountPV', {
                                    required: watchHasOwnPV,
                                    pattern: {
                                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                        message: intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.error.pattern',
                                        }),
                                    },
                                    min: {
                                        value: 0,
                                        message: intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.error.min',
                                        }),
                                    },
                                })}
                                label={
                                    getValues('ownGeneration.isKWhUnit') === 'true'
                                        ? intl.formatMessage({
                                              id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.kWhlabel',
                                          })
                                        : intl.formatMessage({
                                              id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.kWplabel',
                                          })
                                }
                                placeholder={intl.formatMessage({
                                    id: 'deals.consumptionAnalysis.ownElectricity.amountPVInput.placeholder',
                                })}
                                bottomLeftErrorLabel={
                                    errors?.ownGeneration?.amountPV?.message
                                        ? errors?.ownGeneration?.amountPV.message
                                        : ''
                                }
                                bottomRightLabel={getValueAndUnit}
                                hasError={!!errors?.ownGeneration?.amountPV}
                                isDisabled={!watchHasOwnPV || !isEdit}
                            />
                        </div>
                    </fieldset>
                    <fieldset>
                        <h2>{intl.formatMessage({ id: 'deals.consumptionAnalysis.fees.title' })}</h2>
                        {getValues('fees.hasFees') && !isResettingGridFees ? (
                            <>
                                <div className="my-4">
                                    {intl.formatMessage({
                                        id: 'deals.consumptionAnalysis.fees.netFeesAlreadyCalculated',
                                    })}{' '}
                                    <strong>{gridFeesFullAddress}</strong>
                                </div>
                                <div className="my-4">
                                    <button
                                        disabled={submitPending || !isEdit}
                                        className="btn btn-neutral btn-md mt-0"
                                        onClick={e => resetGridFees(e)}
                                    >
                                        {intl.formatMessage({ id: 'deals.consumptionAnalysis.fees.resetGridFees' })}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <>
                                <label className="flex cursor-pointer my-4">
                                    <input
                                        {...register('fees.hasFees')}
                                        type="checkbox"
                                        className="checkbox mr-2"
                                        disabled={submitPending || !isEdit}
                                    />
                                    <span className="label-text text-base">
                                        {intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.fees.hasFeesCheckbox.label',
                                        })}
                                    </span>
                                </label>
                                <Controller
                                    name="fees.address.google"
                                    rules={{
                                        required:
                                            watchHasFees &&
                                            intl.formatMessage({
                                                id: 'deals.consumptionAnalysis.fees.address.error.googleError',
                                            }),
                                        validate: (_input, values) => {
                                            if (!watchHasFees) {
                                                return true;
                                            }
                                            if (
                                                !values.fees.address.houseNumber ||
                                                !values.fees.address.street ||
                                                !values.fees.address.postCode ||
                                                !values.fees.address.location
                                            )
                                                return intl.formatMessage({
                                                    id: 'deals.consumptionAnalysis.fees.address.error.googleError',
                                                });
                                        },
                                    }}
                                    control={control}
                                    render={({ field }) => (
                                        <label className={classNames('form-control w-full max-w-xs')}>
                                            <div className="label">
                                                <span className="label-text font-bold text-base text-stone-500">
                                                    {intl.formatMessage({
                                                        id: 'deals.consumptionAnalysis.fees.address.label',
                                                    })}
                                                </span>
                                            </div>
                                            <GoogleAutocomplete
                                                disabled={!watchHasFees || !isEdit}
                                                hasError={!!errors?.fees?.address}
                                                onPlaceSelected={(place, validatedAddress) => {
                                                    setValue('fees.address', {
                                                        google: place?.formatted_address ?? place?.name ?? '',
                                                        street: '',
                                                        houseNumber: '',
                                                        postCode: '',
                                                        location: '',
                                                    });
                                                    clearErrors('fees.address.google');

                                                    if (validatedAddress?.errors) {
                                                        setError('fees.address.google', {
                                                            type: 'validate',
                                                            message: intl.formatMessage(
                                                                {
                                                                    id: 'deals.consumptionAnalysis.fees.address.error.pattern',
                                                                },
                                                                {
                                                                    value: validatedAddress.errors
                                                                        .map(error =>
                                                                            intl.formatMessage({
                                                                                id: `deals.consumptionAnalysis.fees.address.${error}`,
                                                                            }),
                                                                        )
                                                                        .join(', '),
                                                                },
                                                            ),
                                                        });
                                                    } else {
                                                        setValue('fees.address', {
                                                            google: place?.formatted_address ?? '',
                                                            ...validatedAddress.data,
                                                        });
                                                    }
                                                }}
                                            />

                                            <div className="label">
                                                {errors.fees?.address && (
                                                    <span
                                                        className={classNames('label-text-alt', { 'text-error': true })}
                                                    >
                                                        {errors.fees.address.google?.message}
                                                    </span>
                                                )}
                                                {getValues().fees.address.google && !errors.fees?.address && (
                                                    <span className={classNames('label-text-alt')}>
                                                        {intl.formatMessage(
                                                            {
                                                                id: 'deals.consumptionAnalysis.fees.address.bottomLabel',
                                                            },
                                                            { address: getValues().fees.address.google },
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        </label>
                                    )}
                                />
                            </>
                        )}

                        <div className="mt-4 mb-6 join">
                            <input
                                {...register('fees.isSlp')}
                                onChange={e => {
                                    setValue('fees.supplyVoltage', 'NSP');
                                    setValue('fees.meterVoltage', 'NSP');
                                }}
                                type="radio"
                                value="true"
                                className="btn btn-sm join-item"
                                aria-label={intl.formatMessage({
                                    id: 'deals.consumptionAnalysis.fees.profile.SLP',
                                })}
                                disabled={!watchHasFees || submitPending || !isEdit}
                            />
                            <input
                                {...register('fees.isSlp')}
                                onChange={e => {
                                    setValue('fees.supplyVoltage', 'MSP');
                                    setValue('fees.meterVoltage', 'MSP');
                                }}
                                type="radio"
                                value="false"
                                className="btn btn-sm join-item"
                                aria-label={intl.formatMessage({
                                    id: 'deals.consumptionAnalysis.fees.profile.RLM',
                                })}
                                disabled={!watchHasFees || submitPending || !isEdit}
                            />
                        </div>

                        <h3 className="mt-4">
                            {intl.formatMessage({ id: 'deals.consumptionAnalysis.fees.voltageInformation.title' })}
                        </h3>
                        <div className="grid grid-cols-2 gap-4 items-end">
                            <label className="form-control w-full max-w-xs">
                                <div className="label">
                                    <span className="label-text font-bold text-base text-stone-500">
                                        {intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.label',
                                        })}
                                    </span>
                                </div>
                                <select
                                    disabled={!watchHasFees || submitPending || !isEdit}
                                    className="select select-bordered"
                                    {...register('fees.supplyVoltage')}
                                >
                                    <option value={'NSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.NSP`,
                                        })}
                                    </option>
                                    <option value={'MSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.MSP`,
                                        })}
                                    </option>
                                    <option value={'HSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.HSP`,
                                        })}
                                    </option>
                                    <option value={'MSP_NSP_UMSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.MSP_NSP_UMSP`,
                                        })}
                                    </option>
                                    <option value={'HSP_MSP_UMSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.supplyVoltage.HSP_MSP_UMSP`,
                                        })}
                                    </option>
                                </select>
                            </label>
                            <label className="form-control w-full max-w-xs">
                                <div className="label">
                                    <span className="label-text font-bold text-base text-stone-500">
                                        {intl.formatMessage({
                                            id: 'deals.consumptionAnalysis.fees.voltageInformation.meterVoltage.label',
                                        })}
                                    </span>
                                </div>
                                <select
                                    disabled={!watchHasFees || !isEdit}
                                    className="select select-bordered"
                                    {...register('fees.meterVoltage')}
                                >
                                    <option value={'NSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.meterVoltage.NSP`,
                                        })}
                                    </option>
                                    <option value={'MSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.meterVoltage.MSP`,
                                        })}
                                    </option>
                                    <option value={'HSP'}>
                                        {intl.formatMessage({
                                            id: `deals.consumptionAnalysis.fees.voltageInformation.meterVoltage.HSP`,
                                        })}
                                    </option>
                                </select>
                            </label>
                        </div>
                    </fieldset>
                    {isEdit && (
                        <button disabled={submitPending} className="btn btn-primary btn-md mt-8">
                            {intl.formatMessage({ id: 'deals.consumptionAnalysis.save' })}
                            <LockLockedIcon width={20} height={20} />
                        </button>
                    )}
                </form>
                {!isEdit && (
                    <button
                        disabled={submitPending}
                        className="btn btn-primary btn-md mt-8"
                        onClick={() => setIsEdit(true)}
                    >
                        {intl.formatMessage({ id: 'deals.consumptionAnalysis.edit' })}
                        <PenIcon width={20} height={20} />
                    </button>
                )}
                {!isEdit && (
                    <Link
                        to={`/deals/${dealId}/offers/create`}
                        relative="path"
                        className="btn btn-md  btn-accent mt-8 ml-4"
                    >
                        {intl.formatMessage({ id: 'deals.createOfferCTA' })}
                    </Link>
                )}
                <ToastWrapper />
            </div>
        </div>
    );
}
