import classNames from 'classnames';
import React, { ComponentProps } from 'react';
import { RegisterOptions } from 'react-hook-form';

export type TextInputProps = {
    id?: string;
    className?: string;
    placeholder?: string;
    defaultValue?: string;
} & Partial<RegisterOptions> &
    Pick<ComponentProps<'input'>, 'aria-invalid'>;

const TextInput = React.forwardRef(({ className, ...rest }: TextInputProps, ref) => {
    return (
        <input
            type="text"
            ref={ref}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            {...(rest as any)}
            className={classNames('input input-bordered aria-[invalid=true]:input-error w-full max-w-x', className)}
        />
    );
});

TextInput.displayName = 'TextInput';

export default TextInput;
