import { SlideTable } from '../types.ts';

export const calculateSumTabeRow = (table: SlideTable) => {
    const summaryRow = table.data.reduce<number[]>((acc, row, tableIndex) => {
        row.forEach((cell, i) => {
            acc[i] = typeof cell === 'number' ? acc[i] + cell : acc[i];
        });
        return acc;
    }, new Array<number>(table.columns.length).fill(0));
    return [undefined, ...summaryRow]; // first entry is an index row
};
