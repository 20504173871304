import { RouterProvider } from 'react-router';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import { AuthenticatedWithCognito } from '@trawa-energy/authentication-ui';
import AuthTRpcProviders from './AuthTRpcProviders';
import { config } from './config';
import { translations } from './locales/localeConfig.ts';
import { router } from './router';

export function App() {
    const locale = 'de-DE';
    const timeZone = 'Europe/Berlin';

    return (
        <div>
            {config.environment === 'staging' && (
                <div
                    style={{
                        height: '20px',
                        background: `repeating-linear-gradient(
                            45deg,
                            yellow,
                            yellow 10px,
                            black 10px,
                            black 20px
                          )`,
                        backgroundSize: '20px 20px',
                        backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
                    }}
                ></div>
            )}
            <IntlProvider locale={locale} timeZone={timeZone} messages={flatten(translations[locale])}>
                <AuthenticatedWithCognito {...config.amplifyAuth}>
                    <AuthTRpcProviders>
                        <RouterProvider
                            router={router}
                            future={{
                                v7_startTransition: true,
                            }}
                        />
                    </AuthTRpcProviders>
                </AuthenticatedWithCognito>
            </IntlProvider>
        </div>
    );
}
