import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import './index.css';
import { initSentry } from './utils/setupSentry.ts';
import { initMixpanel } from './utils/setupMixpanel.ts';
import { FeaturevisorProvider } from '@featurevisor/react';
import { initFeaturevisor } from './utils/setupFeatureVisor.ts';

initSentry();
initMixpanel();
const featurevisorInstance = initFeaturevisor();

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <FeaturevisorProvider instance={featurevisorInstance}>
            <App />
        </FeaturevisorProvider>
    </React.StrictMode>,
);
