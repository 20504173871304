import { Link, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { HeartIcon } from '@trawa-energy/ui-kit';
import classNames from 'classnames';
import { ErrorCrossIcon, SuccessTickIcon } from '../components/Icons.tsx';
import { Dropdown } from '../components/Dropdown.tsx';
import { PresentationItem } from '../components/PresentationItem.tsx';
import { Mixpanel } from '../../utils/setupMixpanel.ts';
import driveIcon from '../../assets/drive.png';
import slidesIcon from '../../assets/slides.png';
import docsIcon from '../../assets/docs.png';
import { useFeatureFlags } from '../hooks/useFeatureFlags.tsx';
import { useOfferExport } from '../hooks/useOfferExport.ts';

type SpotOption = 'low' | 'medium' | 'high';

export function Summary() {
    const { dealId, offerId } = useParams();
    const intl = useIntl();

    if (!dealId || !offerId) {
        throw new Error("Can't render Summary without dealContext");
    }

    const { isContractCreationEnabled } = useFeatureFlags();

    const { data: resultsData, isError: isErrorGetOffers } = trpc.deals.getResults.useQuery(
        {
            dealId,
            offerId,
        },
        { enabled: !!dealId && !!offerId },
    );

    const { data: offerSummary, refetch: refetchOfferOptions } = trpc.deals.getOfferSpotOptions.useQuery(
        {
            dealId,
            offerId,
        },
        { enabled: !!dealId && !!offerId },
    );

    const {
        presentations,
        presentationTemplates,
        contracts,
        contractTemplates,
        generateOfferExportContract,
        generateOfferPresentation,
        isGeneratingContract,
        isGeneratingPresentation,
        exportContractFolderUrl,
        exportPresentationFolderUrl,
        exportPresentationFolderName,
        exportContractFolderName,
    } = useOfferExport({ dealId, offerId });

    const { mutateAsync: setFavouriteMutation } = trpc.deals.setFavouriteOfferSpotOption.useMutation({
        onSuccess: () => refetchOfferOptions(),
    });

    const spotCategoryOrder: SpotOption[] = useRef<SpotOption[]>(['low', 'medium', 'high']).current;
    const [isFavourite, setIsFavourite] = useState<number | null>(null);

    const templateSlideDeckOptions = useMemo(
        () =>
            presentationTemplates?.map(template => ({
                id: template.id,
                value: template.name,
            })) ?? [],
        [presentationTemplates],
    );

    const templateContractOptions = useMemo(() => {
        return contractTemplates?.map(template => ({ id: template.id, value: template.name })) ?? [];
    }, [contractTemplates]);

    useEffect(() => {
        if (isErrorGetOffers) {
            Sentry.withScope(function (scope) {
                scope.setLevel('error');
                scope.setTags({ dealId: dealId, offerId: offerId });
                Sentry.captureException(new Error('Error fetching results data'));
            });
        }
    }, [dealId, offerId, isErrorGetOffers]);

    useEffect(() => {
        //set active option by matching offer data with spotOptionCategoryOrder
        if (offerSummary) {
            const favouriteOption = offerSummary?.find(offer => offer.spotOptionIsFavourite);
            if (favouriteOption) {
                const index = spotCategoryOrder.indexOf(favouriteOption.spotOption as SpotOption);
                setIsFavourite(index);
            }
        }
    }, [offerSummary, spotCategoryOrder]);

    const setFavouriteOption = useCallback(
        (index: number) => setFavouriteMutation({ dealId, offerId, spotOption: spotCategoryOrder[index] }),
        [setFavouriteMutation, dealId, offerId, spotCategoryOrder],
    );

    if (isErrorGetOffers) {
        return <div className="my-8">{intl.formatMessage({ id: 'deals.summary.error' })}</div>;
    }

    if (!resultsData || !offerSummary) {
        return null;
    }

    const results = resultsData.scenarios.sort((a, b) => a.meta_data.spot_exposure - b.meta_data.spot_exposure);

    const summaries = results.map(result => {
        const totalBuy = result.result_data.contract_components.demand_share.total.volume_buy;
        const demand = result.meta_data.volume_demand;
        const priceAfterSellTotal = result.result_data.contract_components.fees_and_taxes.price_after_sell_total;
        const autonomyWind = result.result_data.contract_components.demand_share.wind.autonomy;
        const autonomySolar = result.result_data.contract_components.demand_share.solar.autonomy;
        const priceShockAlert =
            result.result_data.contract_components.scenario_analysis['price_portfolio_2.0x_spot_shock'] *
                result.result_data.contract_components.scenario_analysis['cost_portfolio_2.0x_spot_shock'] >
                result.result_data.contract_components.scenario_analysis['price_portfolio_1.0x_spot_shock'] *
                    result.result_data.contract_components.scenario_analysis['cost_portfolio_1.0x_spot_shock'] ||
            result.result_data.contract_components.scenario_analysis['price_portfolio_1.2x_spot_shock'] *
                result.result_data.contract_components.scenario_analysis['cost_portfolio_1.2x_spot_shock'] >
                result.result_data.contract_components.scenario_analysis['price_portfolio_1.0x_spot_shock'] *
                    result.result_data.contract_components.scenario_analysis['cost_portfolio_1.0x_spot_shock'];

        return {
            totalBuy,
            coverageAbsolute: totalBuy - demand,
            coveragePercentage: (totalBuy - demand) / demand,
            priceAfterSellTotal,
            autonomyRenewables: autonomyWind + autonomySolar,
            priceShockAlert,
        };
    });

    return (
        <div>
            <div className="my-8">
                <h1>
                    {intl.formatMessage(
                        { id: 'deals.summary.subHeading' },
                        {
                            offerName:
                                offerSummary.length > 0 ? offerSummary[0].offerName : results[0].meta_data.offer_name,
                        },
                    )}
                </h1>
                <section className="my-8">
                    <h2 className="my-4">{intl.formatMessage({ id: 'deals.summary.summary.title' })}</h2>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>{intl.formatMessage({ id: 'deals.summary.summary.results.startDate' })}</td>
                                <td>
                                    {intl.formatDate(
                                        offerSummary.length > 0
                                            ? offerSummary[0].contractStartDatetime
                                            : results[0].meta_data.start_datetime,
                                        {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage({ id: 'deals.summary.summary.results.durationYears' })}</td>
                                <td>
                                    {intl.formatMessage(
                                        { id: 'deals.summary.summary.results.durationYearsResult' },
                                        {
                                            duration:
                                                offerSummary.length > 0
                                                    ? offerSummary[0].contractDurationYears
                                                    : results[0].meta_data.contract_duration_years,
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage({ id: 'deals.summary.summary.results.volumeDemand' })}</td>
                                <td>
                                    {intl.formatMessage(
                                        { id: 'deals.general.volumekWh' },
                                        {
                                            volume: intl.formatNumber(
                                                offerSummary.length > 0
                                                    ? Number(offerSummary[0].volumeDemand)
                                                    : results[0].meta_data.volume_demand,
                                                {
                                                    maximumFractionDigits: 0,
                                                },
                                            ),
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>{intl.formatMessage({ id: 'deals.summary.summary.results.trawaFee' })}</td>
                                <td>
                                    {intl.formatMessage(
                                        { id: 'deals.summary.summary.results.trawaFeeCurrency' },
                                        {
                                            price: intl.formatNumber(
                                                results[0].result_data.contract_components.fees_and_taxes
                                                    .price_trawa_fee * 100,
                                                {
                                                    maximumFractionDigits: 3,
                                                },
                                            ),
                                        },
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
                <section>
                    <h2 className="my-4">{intl.formatMessage({ id: 'deals.summary.options.title' })}</h2>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>{intl.formatMessage({ id: 'deals.summary.options.results.kpis' })}</th>
                                {results.map((scenario, index) => (
                                    <th className="p-0" key={index}>
                                        <div
                                            className={classNames('mx-1 pb-2', {
                                                'rounded-t-md bg-teal-100 ': isFavourite === index,
                                            })}
                                            data-testid={isFavourite === index ? 'favouriteOption' : ''}
                                        >
                                            <div
                                                onClick={async () => {
                                                    await setFavouriteOption(index);
                                                    Mixpanel.track('Offer selected', {
                                                        'Spot option': spotCategoryOrder[index],
                                                    });
                                                }}
                                                className={classNames(
                                                    'flex justify-center items-center gap-2 py-3 px-10 ',
                                                    { 'rounded-t-md bg-teal-400 m-0': isFavourite === index },
                                                    { 'rounded bg-gray-100': isFavourite !== index },
                                                )}
                                            >
                                                <span className="font-normal">
                                                    {intl.formatMessage(
                                                        { id: 'deals.summary.options.results.spotOption' },
                                                        {
                                                            exposure: scenario.meta_data.spot_exposure,
                                                            option: spotCategoryOrder[index],
                                                        },
                                                    )}
                                                </span>
                                                <HeartIcon className="fill" width={20} height={20} />
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {intl.formatMessage({ id: 'deals.summary.options.results.tableBody.volumeBuy' })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            {intl.formatMessage(
                                                { id: 'deals.general.volumekWh' },
                                                {
                                                    volume: intl.formatNumber(summary.totalBuy, {
                                                        maximumFractionDigits: 0,
                                                    }),
                                                },
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>
                                    {intl.formatMessage({
                                        id: 'deals.summary.options.results.tableBody.coveragePercentage',
                                    })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            {intl.formatNumber(summary.coveragePercentage, {
                                                style: 'percent',
                                                maximumFractionDigits: 2,
                                            })}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>
                                    {intl.formatMessage({
                                        id: 'deals.summary.options.results.tableBody.coverageAbsolute',
                                    })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            {intl.formatMessage(
                                                { id: 'deals.general.volumekWh' },
                                                {
                                                    volume: intl.formatNumber(summary.coverageAbsolute, {
                                                        maximumFractionDigits: 0,
                                                    }),
                                                },
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>
                                    {intl.formatMessage({
                                        id: 'deals.summary.options.results.tableBody.energyPrice',
                                    })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            {intl.formatMessage(
                                                { id: 'deals.summary.options.results.tableBody.energyPriceValue' },
                                                {
                                                    price: intl.formatNumber(summary.priceAfterSellTotal * 100, {
                                                        maximumFractionDigits: 3,
                                                    }),
                                                },
                                            )}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>
                                    {intl.formatMessage({
                                        id: 'deals.summary.options.results.tableBody.autonomyRenewables',
                                    })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            {intl.formatNumber(summary.autonomyRenewables, {
                                                style: 'percent',
                                                maximumFractionDigits: 2,
                                            })}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td>
                                    {intl.formatMessage({
                                        id: 'deals.summary.options.results.tableBody.priceShockAlert',
                                    })}
                                </td>
                                {summaries.map((summary, index) => (
                                    <td key={index} className="p-0">
                                        <div
                                            className={classNames(' mx-1 p-3 flex justify-center', {
                                                'bg-teal-100 ': isFavourite === index,
                                            })}
                                        >
                                            <div className="flex justify-center">
                                                {summary.priceShockAlert ? <SuccessTickIcon /> : <ErrorCrossIcon />}
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <td></td>
                                {results.map((_scenario, index) => (
                                    <td
                                        key={index}
                                        className={classNames('p-0', {
                                            'align-top': isFavourite !== index,
                                        })}
                                    >
                                        <div
                                            className={classNames(' mx-1 p-3 ', {
                                                'bg-teal-100 mb-1 rounded-b-md': isFavourite === index,
                                            })}
                                        >
                                            <div className="flex-col text-center">
                                                <Link
                                                    className="btn btn-neutral btn-sm"
                                                    to={`/deals/${dealId}/offers/${offerId}/options/${spotCategoryOrder[index]}`}
                                                >
                                                    {intl.formatMessage({ id: 'deals.summary.options.goOptionCTA' })}
                                                </Link>
                                            </div>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>

                        <tfoot>
                            <tr className="text-left">
                                <td className="text-base font-normal text-gray-900 align-top">
                                    {exportPresentationFolderUrl && (
                                        <p className="flex gap-2">
                                            <img src={driveIcon} width="24" height="24" alt="google drive icon" />
                                            <a
                                                className="link"
                                                href={exportPresentationFolderUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {exportPresentationFolderName}
                                            </a>
                                        </p>
                                    )}
                                </td>
                                {spotCategoryOrder.map((option, index) => (
                                    <td key={index} className="max-w-60 align-top">
                                        {isFavourite === index && (
                                            <Dropdown
                                                label={intl.formatMessage({
                                                    id: 'deals.summary.options.results.tableFooter.newSlideDeckLabel',
                                                })}
                                                items={templateSlideDeckOptions}
                                                onSelect={async template => {
                                                    await generateOfferPresentation({
                                                        dealId,
                                                        offerId: offerId,
                                                        spotOption: spotCategoryOrder[index],
                                                        templateId:
                                                            presentationTemplates?.find(t => t.name === template?.value)
                                                                ?.id ?? '',
                                                    });
                                                    Mixpanel.track('Offer exported', {
                                                        'Slide template': template?.value,
                                                        'Offer Id': offerId,
                                                        'Deal Id': dealId,
                                                        'Spot option': spotCategoryOrder[index],
                                                    });
                                                }}
                                                isGenerating={isGeneratingPresentation}
                                            />
                                        )}
                                        {presentations
                                            ?.filter(presentation => presentation.spotOption === option)
                                            .map(presentation => (
                                                <PresentationItem
                                                    key={presentation?.offerExportCreatedAt}
                                                    template={
                                                        presentation.status === 'running'
                                                            ? (presentationTemplates?.find(
                                                                  template =>
                                                                      template.id ===
                                                                      presentation.offerExportTemplateName,
                                                              )?.name ?? 'Template')
                                                            : presentation?.offerExportTemplateName
                                                    }
                                                    fileName={presentation?.offerExportFileName ?? 'no filename'}
                                                    fileUrl={presentation?.offerExportFileUrl ?? 'no url'}
                                                    status={presentation?.status}
                                                    logoUrl={slidesIcon}
                                                />
                                            ))}
                                    </td>
                                ))}
                            </tr>

                            {isContractCreationEnabled && (
                                <tr className="text-left">
                                    <td className="text-base font-normal text-gray-900 align-top">
                                        {exportContractFolderUrl && (
                                            <p className="flex gap-2">
                                                <img src={driveIcon} width="24" height="24" alt="google drive icon" />
                                                <a
                                                    className="link"
                                                    href={exportContractFolderUrl}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    {exportContractFolderName}
                                                </a>
                                            </p>
                                        )}
                                    </td>
                                    {spotCategoryOrder.map((option, index) => (
                                        <td key={index} className="max-w-60 align-top">
                                            {isFavourite === index && (
                                                <Dropdown
                                                    label={intl.formatMessage({
                                                        id: 'deals.summary.options.results.tableFooter.newContractLabel',
                                                    })}
                                                    items={templateContractOptions}
                                                    onSelect={async template => {
                                                        await generateOfferExportContract({
                                                            dealId,
                                                            offerId: offerId,
                                                            spotOption: spotCategoryOrder[index],
                                                            templateId:
                                                                contractTemplates?.find(t => t.name === template?.value)
                                                                    ?.id ?? '',
                                                        });
                                                        Mixpanel.track('Contract created', {
                                                            'Contract template': template?.value,
                                                            'Offer Id': offerId,
                                                            'Deal Id': dealId,
                                                            'Spot option': spotCategoryOrder[index],
                                                        });
                                                    }}
                                                    isGenerating={isGeneratingContract}
                                                />
                                            )}
                                            {contracts
                                                ?.filter(contract => contract.spotOption === option)
                                                .map(contract => (
                                                    <PresentationItem
                                                        key={contract?.offerExportCreatedAt}
                                                        template={
                                                            contract.status === 'running'
                                                                ? (contractTemplates?.find(
                                                                      template =>
                                                                          template.id ===
                                                                          contract.offerExportTemplateName,
                                                                  )?.name ?? 'Template')
                                                                : contract?.offerExportTemplateName
                                                        }
                                                        fileName={contract?.offerExportFileName ?? 'no filename'}
                                                        fileUrl={contract?.offerExportFileUrl ?? 'no url'}
                                                        status={contract?.status}
                                                        logoUrl={docsIcon}
                                                    />
                                                ))}
                                        </td>
                                    ))}
                                </tr>
                            )}
                        </tfoot>
                    </table>
                </section>
            </div>
        </div>
    );
}
