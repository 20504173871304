import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { trpcBackoffice as trpc } from '../utils/trpc';
import useDebounce from '../utils/useDebounce';
import TextInput from '../shared/inputs/TextInput';
import classNames from 'classnames';
import { UserForm } from './UserForm';
import { ChangeEvent, useState } from 'react';

type FilterValue = { accountId: string | undefined; text: string | undefined };

export function Users() {
    const [searchParams, setSearchParams] = useSearchParams();
    const filter = {
        accountId: searchParams.get('accountId') ?? undefined,
        text: searchParams.get('text') ?? undefined,
    };
    const navigate = useNavigate();
    const debouncedFilter = useDebounce(filter, 1000);
    const { data: accounts } = trpc.accounts.getMany.useQuery();
    const [addUserDialog, setAddUserDialog] = useState<HTMLDialogElement | null>(null);

    const { data: users, refetch: refetchUsers } = trpc.users.getAll.useQuery(debouncedFilter);
    return (
        <div className="space-y-5">
            <h3 className="text-lg">Users</h3>
            <div className="flex justify-between gap-4">
                <Filter
                    className="flex-1"
                    defaultValue={filter}
                    onChange={value => {
                        const newSearchParams = new URLSearchParams();
                        if (value.accountId) newSearchParams.append('accountId', value.accountId);
                        if (value.text) newSearchParams.append('text', value.text);
                        setSearchParams(newSearchParams);
                    }}
                />
            </div>
            {debouncedFilter.accountId && (
                <>
                    <button
                        className="btn btn-primary btn-outline"
                        type="button"
                        onClick={() => addUserDialog?.showModal()}
                    >
                        Add user
                    </button>

                    <dialog ref={setAddUserDialog} className="modal">
                        <div className="modal-box">
                            <button
                                className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
                                onClick={() => addUserDialog?.close()}
                            >
                                ✕
                            </button>
                            <UserForm
                                accountId={debouncedFilter.accountId}
                                onSaved={async () => {
                                    addUserDialog?.close();
                                    await refetchUsers();
                                }}
                            />
                        </div>
                    </dialog>
                </>
            )}
            <div>
                <table className="table table-sm">
                    <tbody>
                        {users
                            ?.filter(x => !x.isDefault)
                            .map(x => (
                                <tr key={x.id} className="hover cursor-pointer">
                                    <td onClick={() => navigate(`/users/${x.id}`)}>
                                        {x.firstName} {x.lastName}
                                    </td>
                                    <td onClick={() => navigate(`/users/${x.id}`)}>{x.email}</td>
                                    <td>
                                        <Link className="link link-primary" to={`/accounts/${x.id}`}>
                                            {accounts?.find(c => c.id === x.accountId)?.name}
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function Filter({
    className,
    defaultValue,
    onChange,
}: {
    className: string;
    defaultValue: FilterValue;
    onChange: (value: FilterValue) => void;
}) {
    const { data: accounts } = trpc.accounts.getMany.useQuery();

    return (
        <div className={classNames('flex gap-4', className)}>
            <TextInput
                placeholder="Search"
                className="flex-1"
                defaultValue={defaultValue.text}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    onChange({ ...defaultValue, text: e.target.value?.trim() || undefined })
                }
            />

            <div className="flex-1">
                {accounts ? (
                    <select
                        className="select"
                        defaultValue={defaultValue.accountId ?? ''}
                        onChange={e => onChange({ ...defaultValue, accountId: e.target.value || undefined })}
                    >
                        <option value="">all accounts</option>
                        {accounts.map(c => (
                            <option key={c.id} value={c.id}>
                                {c.name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <div className="h-12 skeleton"></div>
                )}
            </div>
        </div>
    );
}
