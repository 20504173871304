import { FieldErrors, UseFormClearErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FormValues, Products } from '../../pages/CreateOffer.tsx';
import { useIntl } from 'react-intl';
import { ChangeEvent, useCallback } from 'react';
import classNames from 'classnames';
import { convertToKwh, convertToPercentage } from '../../utils/convertMaxima.ts';

export const ProductsFormPart = ({
    register,
    setValue,
    watch,
    yearlyConsumption,
    errors,
    clearErrors,
}: {
    register: UseFormRegister<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    watch: UseFormWatch<FormValues>;
    yearlyConsumption: number;
    errors: FieldErrors<FormValues>;
    clearErrors: UseFormClearErrors<FormValues>;
}) => {
    const intl = useIntl();
    const watchProducts = watch('products');

    const getPercentage = useCallback(
        (value: number) => convertToPercentage(value, yearlyConsumption),
        [yearlyConsumption],
    );
    const getKwh = useCallback((value: number) => convertToKwh(value, yearlyConsumption), [yearlyConsumption]);

    return (
        <fieldset className="my-8">
            <h2>{intl.formatMessage({ id: 'deals.createOffer.offerComposition.title' })}</h2>
            <div className="flex flex-wrap gap-8 item-center">
                {Object.keys(watchProducts.activated).map((field, index) => {
                    return (
                        <div key={index} className="form-control">
                            <label className="label cursor-pointer justify-start">
                                <input
                                    disabled={!yearlyConsumption}
                                    {...register(`products.activated.${field as keyof Products['activated']}`, {
                                        onChange: (value: ChangeEvent<HTMLInputElement>) => {
                                            if (!value.target.checked) {
                                                setValue(`products.number.${field as keyof Products['number']}`, 0);
                                            } else {
                                                setValue(
                                                    `products.number.${field as keyof Products['number']}`,
                                                    watchProducts.isMaximaInKwh === 'true' ? yearlyConsumption : 100,
                                                );
                                            }
                                            clearErrors();
                                        },
                                    })}
                                    type="checkbox"
                                    className="checkbox mr-4"
                                />
                                <span className="label-text">
                                    {intl.formatMessage({
                                        id: `deals.createOffer.offerComposition.products.${index}`,
                                    })}
                                </span>
                            </label>
                            <label className="form-control">
                                <div
                                    className={classNames(
                                        'input input-bordered flex justify-between items-center gap-2 input-md',
                                        {
                                            'input-error':
                                                !!errors?.products?.number?.[field as keyof Products['number']],
                                        },
                                    )}
                                >
                                    <span>max.</span>
                                    <input
                                        className="w-auto flex-shrink"
                                        type="text"
                                        disabled={
                                            !yearlyConsumption ||
                                            !watchProducts.activated[field as keyof Products['activated']]
                                        }
                                        {...register(`products.number.${field as keyof Products['number']}`, {
                                            required: intl.formatMessage({
                                                id: 'deals.createOffer.offerComposition.error.pattern',
                                            }),
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: intl.formatMessage({
                                                    id: 'deals.createOffer.offerComposition.error.pattern',
                                                }),
                                            },
                                            min: {
                                                value: 0,
                                                message: intl.formatMessage({
                                                    id: 'deals.createOffer.offerComposition.error.min',
                                                }),
                                            },
                                            max: {
                                                value: watchProducts.isMaximaInKwh === 'true' ? yearlyConsumption : 100,
                                                message: intl.formatMessage(
                                                    {
                                                        id: 'deals.createOffer.offerComposition.error.max',
                                                    },
                                                    {
                                                        value:
                                                            watchProducts.isMaximaInKwh === 'true'
                                                                ? yearlyConsumption
                                                                : 100,
                                                    },
                                                ),
                                            },
                                        })}
                                        size={
                                            watchProducts.isMaximaInKwh === 'true'
                                                ? watchProducts.number[field as keyof Products['number']].toString()
                                                      .length === 0
                                                    ? 1
                                                    : watchProducts.number[field as keyof Products['number']].toString()
                                                          .length + 1
                                                : 3
                                        }
                                    />
                                    <span>{watchProducts.isMaximaInKwh === 'true' ? 'kWh' : '%'}</span>
                                </div>
                                <div className="label">
                                    {!!errors?.products?.number?.[field as keyof Products['number']] && (
                                        <span
                                            className={classNames('label-text-alt', {
                                                'text-warning-content':
                                                    !!errors?.products?.number?.[field as keyof Products['number']],
                                            })}
                                        >
                                            {errors?.products?.number?.[field as keyof Products['number']]?.message}
                                        </span>
                                    )}
                                </div>
                            </label>
                        </div>
                    );
                })}
                <div key="spot" className="form-control">
                    <label className="label cursor-pointer justify-start">
                        <input disabled={true} type="checkbox" className="checkbox mr-4" checked={true} />
                        <span className={classNames('label-text', { 'text-black': yearlyConsumption })}>
                            {intl.formatMessage({
                                id: `deals.createOffer.offerComposition.products.5`,
                            })}
                        </span>
                    </label>
                    <label className="input input-bordered flex items-center gap-2 input-md">
                        <span className={classNames({ 'text-black': yearlyConsumption })}>max.</span>
                        <input
                            disabled={true}
                            className={classNames('w-auto flex-shrink', { 'text-black': yearlyConsumption })}
                            type="text"
                            value={watchProducts.isMaximaInKwh === 'true' ? yearlyConsumption?.toFixed(0) : 100}
                            size={
                                watchProducts.isMaximaInKwh === 'true'
                                    ? yearlyConsumption?.toFixed(0).toString().length + 1
                                    : 3
                            }
                        />
                        <span className={classNames({ 'text-black': yearlyConsumption })}>
                            {watchProducts.isMaximaInKwh === 'true' ? 'kWh' : '%'}
                        </span>
                    </label>
                </div>
            </div>
            <label>
                <span className="mr-4">
                    {intl.formatMessage({
                        id: 'deals.createOffer.offerComposition.maximaToggle.label',
                    })}
                </span>
                <div className="join my-4">
                    <input
                        {...register('products.isMaximaInKwh')}
                        disabled={!yearlyConsumption}
                        type="radio"
                        value="true"
                        className="btn btn-sm join-item"
                        aria-label={intl.formatMessage({
                            id: 'deals.createOffer.offerComposition.maximaToggle.kwh',
                        })}
                    />
                    <input
                        {...register('products.isMaximaInKwh', {
                            onChange: () => {
                                if (watchProducts.isMaximaInKwh === 'true') {
                                    setValue('products.number.wind', getKwh(watchProducts.number.wind));
                                    setValue('products.number.solar', getKwh(watchProducts.number.solar));
                                    setValue('products.number.base', getKwh(watchProducts.number.base));
                                    setValue('products.number.peak', getKwh(watchProducts.number.peak));
                                    setValue('products.number.offpeak', getKwh(watchProducts.number.offpeak));
                                } else {
                                    setValue('products.number.wind', getPercentage(watchProducts.number.wind));
                                    setValue('products.number.solar', getPercentage(watchProducts.number.solar));
                                    setValue('products.number.base', getPercentage(watchProducts.number.base));
                                    setValue('products.number.peak', getPercentage(watchProducts.number.peak));
                                    setValue('products.number.offpeak', getPercentage(watchProducts.number.offpeak));
                                }
                            },
                        })}
                        disabled={!yearlyConsumption}
                        type="radio"
                        value="false"
                        className="btn btn-sm join-item"
                        aria-label={intl.formatMessage({
                            id: 'deals.createOffer.offerComposition.maximaToggle.percent',
                        })}
                    />
                </div>
            </label>
        </fieldset>
    );
};
