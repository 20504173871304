import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { AuthContext } from './authContext';
import { Amplify } from 'aws-amplify';
import { fetchAuthSession } from 'aws-amplify/auth';

// NOTE:
// amplify-ui is in a bad shape with substantial technical debt and broken types unaddressed. But it's very easy to use.
// The intention of this wrapper package is to avoid leaking amplify-ui types, and maybe eventually replace amplify-ui.

type AuthParams = {
    aws_cognito_region: string;
    aws_user_pools_id: string;
    aws_user_pools_web_client_id: string;
    aws_mandatory_sign_in: string;
};

export const changePasswordPath = 'change-password';

export const AuthenticatedWithCognito: FC<PropsWithChildren<AuthParams>> = ({ children, ...authParams }) => {
    const [configured, setConfigured] = useState(false);

    useEffect(() => {
        Amplify.configure({
            Auth: {
                Cognito: {
                    userPoolId: authParams.aws_user_pools_id,
                    userPoolClientId: authParams.aws_user_pools_web_client_id,
                },
            },
        });

        setConfigured(true);
    }, []);

    return (
        configured && (
            <div className="auth-ui-root">
                <Authenticator
                    className="vh-100"
                    formFields={{
                        signIn: {
                            username: {
                                placeholder: 'Enter Your Email',
                                isRequired: true,
                                label: 'Email',
                            },
                        },
                        signUp: {
                            username: {
                                placeholder: 'Enter Your Email',
                                isRequired: true,
                                label: 'Email',
                            },
                        },
                    }}
                    hideSignUp
                >
                    <AuthenticatedWrapper>
                        <div className="auth-authenticated-container">{children}</div>
                    </AuthenticatedWrapper>
                </Authenticator>
            </div>
        )
    );
};

function AuthenticatedWrapper({ children }: { children: React.ReactNode }) {
    const { signOut, user } = useAuthenticator();

    const authContextValue = useMemo(
        () => ({
            signOut,
            getAccessToken: () => fetchAuthSession().then(x => x.tokens!.accessToken.toString()),
            user: user.username,
        }),
        [user, signOut],
    );
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
}
