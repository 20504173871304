import { z } from 'zod';

export type CustomLambdaEvent = z.infer<typeof customLambdaEventSchema>;

export const customLambdaEventSchema = z
    .object({
        dealId: z.string(),
        offerId: z.string(),
        spotOption: z.union([z.literal('low'), z.literal('medium'), z.literal('high')]),
        templateId: z.string(),
        recordId: z.string(),
    })
    .and(
        z
            .object({
                executeGenerateOfferExportPresentation: z.boolean(),
                executeGenerateOfferExportContract: z.undefined().optional(),
            })
            .or(
                z.object({
                    executeGenerateOfferExportPresentation: z.undefined().optional(),
                    executeGenerateOfferExportContract: z.boolean(),
                }),
            ),
    );
