import { useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ArrowLeftIcon } from '@trawa-energy/ui-kit';
import { transformFormValuesToConfig } from '../utils/transformFormValuesToConfig.ts';
import { NavigationWarningModal } from '../components/NavigationWarningModal.tsx';
import { CustomConfigContext } from '../context/CustomConfigContext.tsx';
import { FinishedOfferModal } from '../components/FinishedOfferModal.tsx';
import { Mixpanel } from '../../utils/setupMixpanel.ts';
import { CostOverviewFormPart } from '../components/CreateOfferForm/CostOverviewFormPart.tsx';
import { OfferDetailsFormPart } from '../components/CreateOfferForm/OfferDetailsFormPart.tsx';
import { FrameworkDataFormPart } from '../components/CreateOfferForm/FrameworkDataFormPart.tsx';
import { ProductsFormPart } from '../components/CreateOfferForm/ProductsFormPart.tsx';
import { ErrorBanner } from '../components/ErrorBanner.tsx';
import { Temporal } from 'temporal-polyfill';

export type Products = {
    isMaximaInKwh: string;
    activated: {
        wind: boolean;
        solar: boolean;
        base: boolean;
        peak: boolean;
        offpeak: boolean;
    };
    number: {
        wind: number;
        solar: number;
        base: number;
        peak: number;
        offpeak: number;
    };
};

export type FormValues = {
    general: {
        offerName: string;
        startDate: string;
        duration: number;
        hasIncludedProvision: boolean;
    };
    products: Products;
    costsOverview: {
        isOverride: boolean;
        isSingleOfferMode: boolean;
        ppaPriceWind: number | undefined;
        ppaPriceSolar: number | undefined;
        gooPrice: number;
        tradeMarginFee: number;
        distributionFee: number | undefined;
        trawaInternalFee: number | undefined;
        meterCosts: number | undefined;
        maximumFixed: number;
        spotExposureSingleOffer: number;
        eexPrices: { baseEexPrices: number[]; peakEexPrices: number[] };
    };
};

export const CreateOffer = () => {
    const { dealId } = useParams();
    const configContext = useContext(CustomConfigContext);
    const { mutateAsync: uploadCustomerConfig, isError: isUploadingConfigError } =
        trpc.deals.updateCustomerConfig.useMutation({ onSuccess: () => configContext?.refetch() });

    const { mutateAsync: runProcurementOptimizer, isPending: isStartingRun } =
        trpc.deals.runProcurementOptimizer.useMutation();

    if (!dealId) {
        throw new Error("Can't render DealView without dealId");
    }

    const { data: deal } = trpc.deals.getDeal.useQuery({ dealId }, { enabled: !!dealId });
    const { data: yearlyConsumptionData } = trpc.deals.getYearlyConsumption.useQuery({ dealId }, { enabled: !!dealId });
    const yearlyConsumption = useMemo(
        () => (yearlyConsumptionData ? Math.round(yearlyConsumptionData) : 0),
        [yearlyConsumptionData],
    );
    const intl = useIntl();
    const [isOfferSubmitted, setIsOfferSubmitted] = useState(false);
    const [defaultPricingState, setDefaultPricingState] = useState({ isLoading: false, isError: false });

    const GERMAN_TIMEZONE = 'Europe/Berlin';
    const nextYear = Temporal.Now.plainDateISO(GERMAN_TIMEZONE).toPlainDateTime().add({ years: 1 }).year;

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, dirtyFields },
        getValues,
        setValue,
        reset,
        clearErrors,
    } = useForm<FormValues>({
        defaultValues: {
            general: {
                offerName: '',
                startDate: `${nextYear}-01-01`,
                duration: 2,
                hasIncludedProvision: false,
            },
            products: {
                isMaximaInKwh: 'false',
                activated: {
                    wind: true,
                    solar: true,
                    base: true,
                    peak: true,
                    offpeak: true,
                },
                number: {
                    wind: yearlyConsumption,
                    solar: yearlyConsumption,
                    base: yearlyConsumption,
                    peak: yearlyConsumption,
                    offpeak: yearlyConsumption,
                },
            },
            costsOverview: {
                isOverride: false,
                isSingleOfferMode: false,
                ppaPriceWind: undefined,
                ppaPriceSolar: undefined,
                gooPrice: 0,
                tradeMarginFee: 0,
                distributionFee: 0,
                trawaInternalFee: 0,
                meterCosts: 0,
                maximumFixed: 1,
                spotExposureSingleOffer: 0,
                eexPrices: { baseEexPrices: [], peakEexPrices: [] },
            },
        },
        mode: 'onChange',
    });

    const watchProducts = watch('products');

    useEffect(() => {
        Object.keys(watchProducts.number).forEach(product => {
            setValue(`products.number.${product as keyof Products['number']}`, 100);
        });
    }, [watchProducts.number, yearlyConsumption, setValue]);

    const submitDataToConfig = useCallback(
        async (data: FormValues) => {
            if (configContext?.config) {
                const config = transformFormValuesToConfig({
                    values: data,
                    config: configContext.config,
                    yearlyConsumption,
                });

                await uploadCustomerConfig({ dealId, updatedConfig: config });
                await runProcurementOptimizer({ dealId });
                setIsOfferSubmitted(true);
                Mixpanel.track('Offer created', {
                    'External offer': data.general.hasIncludedProvision,
                    Duration: data.general.duration,
                    'Start date': data.general.startDate,
                    Composition: Object.keys(data.products)
                        .filter(product => product)
                        .join(', '),
                });
                Mixpanel.offerIncrement();
                reset();
            }
        },
        [reset, configContext, dealId, uploadCustomerConfig, runProcurementOptimizer, yearlyConsumption],
    );

    return (
        <div>
            <div className="my-8">
                <div className="pb-8 flex items-center gap-2">
                    <ArrowLeftIcon className="pt-1" />
                    <Link to={`/deals/${dealId}/offers`} relative="path" className="text-xl font-semibold">
                        {intl.formatMessage({ id: 'deals.createOffer.backToSummary' })}
                    </Link>
                </div>
                <h1>{intl.formatMessage({ id: 'deals.createOffer.subHeading' })}</h1>
                <ErrorBanner>
                    {isUploadingConfigError && (
                        <p>{intl.formatMessage({ id: 'deals.createOffer.error.noConfigFile' })}</p>
                    )}
                    {!!deal && !deal?.hasLoadFile && (
                        <p>{intl.formatMessage({ id: 'deals.createOffer.error.noLoadFile' })}</p>
                    )}
                </ErrorBanner>
                <form onSubmit={handleSubmit(submitDataToConfig)}>
                    <FrameworkDataFormPart />
                    <OfferDetailsFormPart register={register} errors={errors} setValue={setValue} />
                    <ProductsFormPart
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        yearlyConsumption={yearlyConsumption}
                        errors={errors}
                        clearErrors={clearErrors}
                    />
                    <CostOverviewFormPart
                        register={register}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                        dirtyFields={dirtyFields}
                        yearlyConsumption={yearlyConsumption}
                        setDefaultPricingState={setDefaultPricingState}
                    />
                    <fieldset className="flex gap-4">
                        <button type="button" disabled className="btn btn-primary">
                            {intl.formatMessage({ id: 'deals.createOffer.copyOfferCTA' })}
                        </button>
                        <button
                            disabled={
                                isStartingRun ||
                                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                                configContext?.isConfigError ||
                                defaultPricingState.isLoading ||
                                defaultPricingState.isError
                            }
                            className="btn btn-primary"
                            type="submit"
                        >
                            {intl.formatMessage({ id: 'deals.createOffer.createOfferCTA' })}
                        </button>
                    </fieldset>
                </form>
            </div>
            {Object.keys(dirtyFields).length > 0 && <NavigationWarningModal />}
            <FinishedOfferModal isOpen={isOfferSubmitted} toggleModal={setIsOfferSubmitted} />
        </div>
    );
};
