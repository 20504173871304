import { Navigation } from './Navigation.tsx';
import { Outlet, useParams } from 'react-router-dom';
import { CustomConfigProvider } from '../context/CustomConfigProvider.tsx';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { useContext, useEffect } from 'react';
import { AuthContext } from '@trawa-energy/authentication-ui';
import { Mixpanel } from '../../utils/setupMixpanel.ts';

export const OfferLayout = () => {
    const { dealId } = useParams();

    const { data } = trpc.deals.getDeal.useQuery({ dealId: dealId! }, { enabled: !!dealId });

    const auth = useContext(AuthContext);

    useEffect(() => {
        if (auth?.user) {
            Mixpanel.identify(auth?.user);
        }
    }, [auth]);
    if (!dealId) {
        return <Outlet />;
    }
    return (
        <CustomConfigProvider>
            <header>
                <div className="flex justify-around">
                    <div className="flex flex-col w-[1000px] mt-16">
                        <h1 className="mb-8">{data?.name}</h1>
                        <Navigation />
                        <Outlet />
                    </div>
                </div>
            </header>
        </CustomConfigProvider>
    );
};
