import * as React from 'react';
import Downshift from 'downshift';
import { useIntl } from 'react-intl';
import { ChevronDownIcon, ChevronUpIcon } from '@trawa-energy/ui-kit';
import classNames from 'classnames';

export const Dropdown = ({
    label,
    items,
    onSelect,
    isGenerating,
}: {
    label: string;
    items: { value: string; id: string; isCategory?: boolean }[];
    onSelect: (selectedItem: { value: string } | null) => Promise<void>;
    isGenerating: boolean;
}) => {
    const intl = useIntl();

    return (
        <Downshift onSelect={onSelect} itemToString={item => (item ? item.value : '')}>
            {({ getToggleButtonProps, getItemProps, getMenuProps, isOpen, highlightedIndex }) => (
                <div className="dropdown dropdown-start my-4 text-base-content font-normal w-full">
                    <button
                        {...getToggleButtonProps()}
                        className="border border-teal-700 rounded-md py-2.5 px-3 flex items-center justify-between mb-1 w-full"
                    >
                        <span className="text-sm text-left  ">{label}</span>
                        <span>
                            {isOpen ? (
                                <ChevronUpIcon className="w-6 h-6 ml-2" />
                            ) : (
                                <ChevronDownIcon className="w-6 h-6 ml-2" />
                            )}
                        </span>
                    </button>
                    <ul
                        {...getMenuProps()}
                        className={`border border-black dropdown-content menu p-2 shadow bg-base-100 rounded-box w-auto text-left z-10 ${
                            !isOpen ? 'hidden' : ''
                        }`}
                    >
                        {isOpen &&
                            items.map((item, index) => (
                                <li
                                    key={item.id}
                                    {...(item.isCategory
                                        ? {} // Don't make category headers selectable
                                        : getItemProps({
                                              index,
                                              item,
                                              className: classNames('p-2 text-base', {
                                                  'bg-blue-600 text-white rounded-lg': highlightedIndex === index,
                                                  disabled: isGenerating,
                                              }),
                                              disabled: isGenerating,
                                          }))}
                                    style={item.isCategory ? { padding: 4, fontSize: '12px' } : {}}
                                >
                                    {item.value}
                                </li>
                            ))}
                    </ul>
                </div>
            )}
        </Downshift>
    );
};
