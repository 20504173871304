import { useContext, useEffect, useState } from 'react';
import { FormValues, Products } from '../pages/CreateOffer.tsx';
import { trpcOffertool as trpc } from '../../utils/trpc.ts';
import { CustomConfigContext } from '../context/CustomConfigContext.tsx';
import { convertCentToEuro, roundWithPrecisionOrUndefined } from '../utils/precisionUtils.ts';

export const useDefaultPriceOverviewValues = ({
    duration,
    products,
    startDate,
    yearlyConsumption,
}: {
    duration: number;
    products: Products['activated'];
    startDate: string;
    yearlyConsumption: number;
}) => {
    const configContext = useContext(CustomConfigContext);
    const [currentData, setCurrentData] = useState<FormValues['costsOverview']>({
        isOverride: false,
        isSingleOfferMode: false,
        ppaPriceWind: 0,
        ppaPriceSolar: 0,
        gooPrice: 0.3, // setting this, currently read out from permanent config
        tradeMarginFee: 0,
        distributionFee: 0,
        trawaInternalFee: 0,
        meterCosts: 0,
        maximumFixed: 0.8,
        spotExposureSingleOffer: 0,
        eexPrices: { baseEexPrices: [], peakEexPrices: [] },
    });
    const [apiDefaultValues, setApiDefaultValues] = useState<FormValues['costsOverview'] | undefined>(undefined);

    const {
        data: apiPricing,
        isLoading,
        isError,
    } = trpc.deals.getOfferDefaultValues.useQuery(
        {
            sector: configContext?.config?.contract_settings?.sector ?? 'industry',
            duration,
            productCombination: products,
            startDate,
            meterCount: configContext?.config?.contract_settings?.number_of_market_locations ?? 1,
            yearlyConsumption,
        },
        {
            enabled:
                !!configContext?.config?.contract_settings.number_of_market_locations ||
                !!configContext?.config?.contract_settings.sector ||
                !!yearlyConsumption,
        },
    );

    useEffect(() => {
        if (apiPricing && configContext?.config) {
            const dataToSet = {
                isOverride: false,
                isSingleOfferMode: false,
                ppaPriceWind: roundWithPrecisionOrUndefined(apiPricing.ppaPriceWind),
                ppaPriceSolar: roundWithPrecisionOrUndefined(apiPricing.ppaPriceSolar),
                gooPrice: roundWithPrecisionOrUndefined(configContext?.config?.prices?.goo_price * 100) ?? 0,
                tradeMarginFee: roundWithPrecisionOrUndefined(apiPricing.ppaTradeMargin) ?? 0,
                distributionFee: roundWithPrecisionOrUndefined(apiPricing.distributionFee),
                trawaInternalFee: roundWithPrecisionOrUndefined(apiPricing.trawaInternalFee),
                meterCosts: roundWithPrecisionOrUndefined(apiPricing.meterCosts),
                maximumFixed:
                    roundWithPrecisionOrUndefined(configContext?.config?.core_opt_settings?.maximum_fixed) ?? 0.8,
                spotExposureSingleOffer:
                    roundWithPrecisionOrUndefined(
                        configContext?.config?.opt_settings?.single_offer_mode?.spot_exposure_single_offer_mode,
                    ) ?? 0,
                eexPrices: {
                    baseEexPrices: apiPricing?.baseEexPrices?.map(convertCentToEuro) ?? [],
                    peakEexPrices: apiPricing?.peakEexPrices?.map(convertCentToEuro) ?? [],
                },
            };

            setApiDefaultValues(dataToSet);
            setCurrentData(dataToSet);
        }
    }, [apiPricing, configContext]);

    const resetDefaultValues = () => {
        setCurrentData(apiDefaultValues ?? currentData);
    };
    return {
        defaultValues: currentData,
        setDefaultValues: setCurrentData,
        resetDefaultValues: resetDefaultValues,
        isError,
        isLoading,
    };
};
