import { z } from 'zod';

const conditionSchema = z.object({
    attribute: z.string(),
    operator: z.string(),
    value: z.array(z.string()),
});

const featureSegmentSchema = z.object({
    key: z.string(),
    conditions: z.string().transform(val => z.array(conditionSchema).parse(JSON.parse(val))),
});

const featureSchema = z.object({
    key: z.string(),
    bucketBy: z.string(),
    traffic: z.array(
        z.object({
            key: z.string(),
            segments: z.string(),
            percentage: z.number(),
            allocation: z.array(z.unknown()),
        }),
    ),
});

export const featureFlagsRootSchema = z.object({
    schemaVersion: z.string(),
    revision: z.string(),
    attributes: z.array(
        z.object({
            key: z.string(),
            type: z.string(),
            capture: z.boolean().optional(),
        }),
    ),
    segments: z.array(featureSegmentSchema),
    features: z.array(featureSchema),
});

export type FeatureSegment = z.infer<typeof featureSegmentSchema>;
export type FeatureFlagsRoot = z.infer<typeof featureFlagsRootSchema>;
