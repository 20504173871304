import React, { useEffect } from 'react';

const FLAGS_SCRIPT_URL = 'flags.js';

export function Flags({ scriptURL = FLAGS_SCRIPT_URL }) {
    useEffect(() => {
        const existingScript = document.querySelector<HTMLScriptElement>(`script[src="${scriptURL}"]`);

        if (!existingScript) {
            const script = document.createElement('script');

            script.src = scriptURL;
            script.async = true;

            document.body.appendChild(script);
        }

        return () => {
            if (existingScript) {
                document.body.removeChild(existingScript);
            }
        };
    }, [scriptURL]);

    return <div id="root">Test</div>;
}
