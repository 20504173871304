import { useIntl } from 'react-intl';
import { useContext, useRef } from 'react';
import { CustomConfigContext } from '../../context/CustomConfigContext.tsx';

export const FrameworkDataFormPart = () => {
    const intl = useIntl();
    const configContext = useContext(CustomConfigContext);
    const watchIsIndustrial = useRef<boolean | undefined>(
        configContext?.config?.contract_settings?.sector === 'industry',
    );
    const watchCounter = useRef<number | undefined>(
        configContext?.config?.contract_settings?.number_of_market_locations,
    );
    const watchOnsiteSolar = useRef<number | undefined>(configContext?.config?.core_opt_settings?.onsite_solar);
    const watchGridFeeAddress = useRef<string | undefined>(configContext?.config?.grid_fees_address?.street);

    return (
        <div>
            <div className="my-8">
                <section className="my-8">
                    <h2 className="my-4">{intl.formatMessage({ id: 'deals.createOffer.frameworkData.title' })}</h2>
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="label-text font-bold text-base text-stone-500">
                                        {watchIsIndustrial.current
                                            ? intl.formatMessage({
                                                  id: 'deals.createOffer.frameworkData.industryAndCommerce',
                                              })
                                            : intl.formatMessage({
                                                  id: 'deals.createOffer.frameworkData.realEstateIndustry',
                                              })}
                                    </span>
                                </td>
                                <td>
                                    {intl.formatMessage(
                                        { id: 'deals.general.counter' },
                                        {
                                            counter: intl.formatNumber(watchCounter.current ?? 0),
                                        },
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="label-text font-bold text-base text-stone-500">
                                        {intl.formatMessage({
                                            id: `deals.createOffer.frameworkData.selfGeneratedElectricity`,
                                        })}
                                    </span>
                                </td>
                                <td>
                                    {watchOnsiteSolar.current !== 0
                                        ? intl.formatMessage({ id: 'deals.general.yes' })
                                        : intl.formatMessage({ id: 'deals.general.no' })}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="label-text font-bold text-base text-stone-500">
                                        {intl.formatMessage({
                                            id: `deals.createOffer.frameworkData.calculatedGridFees`,
                                        })}
                                    </span>
                                </td>
                                <td>
                                    {watchGridFeeAddress.current
                                        ? `${configContext?.config?.grid_fees_address?.street} ${configContext?.config?.grid_fees_address?.house_number}, ${configContext?.config?.grid_fees_address?.post_code}, ${configContext?.config?.grid_fees_address?.location}`
                                        : intl.formatMessage({ id: 'deals.general.no' })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        </div>
    );
};
