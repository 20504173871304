import { z } from 'zod';

const contractSettingsSchema = z.object({
    start_date_year: z.number().int(),
    duration_years: z.number().int(),
    sector: z.union([z.literal('industry'), z.literal('real-estate')]),
    number_of_market_locations: z.number().int(),
    is_external_offer: z.boolean(),
});

const pricesSchema = z.object({
    trawa_internal_fee: z.number(),
    distribution_fee: z.number(),
    goo_price: z.number(),
    monthly_base_fee: z.number(),
    ppa_price_wind_raw: z.number(),
    ppa_price_solar_raw: z.number(),
    ppa_trade_margin: z.number(),
    price_peak_years: z.array(z.number()),
    price_base_years: z.array(z.number()),
    price_electricity_tax: z.number(),
    price_grid_fees: z.number(),
    price_licence_fees: z.number(),
    price_levies: z.number(),
});

const gridFeesAddressSchema = z.object({
    street: z.string(),
    house_number: z.string(),
    post_code: z.string(),
    location: z.string(),
    google: z.string(),
});

export type CustomerConfig = z.infer<typeof latestConfigSchema>;

const latestConfigSchema = z.object({
    version: z.object({
        customer_config_version: z.literal('v2.6'),
    }),
    offer_name: z.string(),
    contract_settings: contractSettingsSchema,
    core_opt_settings: z.object({
        maximum_fixed: z.number(),
        onsite_reduction_activate: z.boolean(),
        onsite_solar: z.number(),
    }),
    prices: pricesSchema,
    opt_settings: z.object({
        single_offer_mode: z.object({
            activate: z.boolean(),
            spot_exposure_single_offer_mode: z.number(),
        }),
        maxima: z.object({
            wind: z.union([z.null(), z.number()]),
            solar: z.union([z.null(), z.number()]),
            base: z.union([z.null(), z.number()]),
            peak: z.union([z.null(), z.number()]),
            offpeak: z.union([z.null(), z.number()]),
        }),
    }),
    grid_fees_address: gridFeesAddressSchema,
});

const v2_5ConfigSchema = z.object({
    version: z.object({
        customer_config_version: z.literal('v2.6'),
    }),
    offer_name: z.string(),
    contract_settings: contractSettingsSchema,
    core_opt_settings: z.object({
        maximum_fixed: z.number(),
        onsite_reduction_activate: z.boolean(),
        onsite_solar: z.number(),
    }),
    prices: pricesSchema,
    opt_settings: z.object({
        single_offer_mode: z.object({
            activate: z.boolean(),
            spot_exposure_single_offer_mode: z.number(),
        }),
        maxima: z.object({
            wind: z.union([z.null(), z.number()]),
            solar: z.union([z.null(), z.number()]),
            base: z.union([z.null(), z.number()]),
            peak: z.union([z.null(), z.number()]),
            offpeak: z.union([z.null(), z.number()]),
        }),
    }),
});

export const customerConfigSchema = latestConfigSchema.or(
    v2_5ConfigSchema.transform(
        (config): CustomerConfig => ({
            ...config,
            version: {
                customer_config_version: 'v2.6',
            },
            grid_fees_address: {
                street: '',
                house_number: '',
                post_code: '',
                location: '',
                google: '',
            },
        }),
    ),
);
